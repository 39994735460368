import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { ReplanDetails } from 'src/app/shared/models/order-moment/replan-details.model';

import {
  AgendaDetails,
  ReplanDetails as ReplanDetailsV1,
} from './model/replan-details.model';

export class OrderMomentAdapter
  implements DataAdapter<ReplanDetailsV1, ReplanDetails>
{
  transform(replanDetails: ReplanDetailsV1): ReplanDetails {
    if (replanDetails.agenda) {
      const newReplanDetails: ReplanDetails = {
        agenda: {
          lastUserTriggeredReplan: this._buildLastUserTriggeredReplan(
            replanDetails.agenda
          ),
          maxReplanDate: replanDetails.agenda.maxReplanDate,
        },
        metadata: replanDetails.metadata,
      };

      return newReplanDetails;
    }
  }

  private _buildLastUserTriggeredReplan(agenda: AgendaDetails): string | null {
    if (agenda === null) return null;

    // Difference of timezone in minutes
    const diffInMinutes = new Date(
      agenda.lastUserTriggeredReplan
    ).getTimezoneOffset();

    return DateTime.fromJSDate(new Date(agenda.lastUserTriggeredReplan))
      .minus({
        minutes: diffInMinutes,
      })
      .toFormat('yyyy-LL-dd HH:mm:ss');
  }
}
