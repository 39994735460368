import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/core/services/config-store.service';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ReplanDetails } from './model/replan-details.model';
import { OrderMomentAdapter } from './order-moment.adapter';

@Injectable({ providedIn: 'root' })
export class OrderMomentService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private patchOrderMoment(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v1',
      'patchOrderMoment',
      replacements
    );
    return this.http.patch(endpoint, payload);
  }

  private _postUserReplanFromDate(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v1',
      'saveReplanFromDate',
      replacements
    );
    return this.http.post(endpoint, payload);
  }

  private _fetchReplanDetails(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'buyOrderConcepts',
      'v2',
      'fetchReplanDetails',
      replacements
    );
    return this.http.get<ReplanDetails>(endpoint);
  }

  patchOrderMoments(
    webshopUUID: string,
    orderMomentUUID: string,
    skipped: boolean
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'orderMomentUUID', value: orderMomentUUID },
    ];

    return this.patchOrderMoment({ skipped }, replacements);
  }

  postUserReplanFromDate(
    webshopUUID: string,
    supplierUUID: string,
    planFromDate: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'supplierUUID', value: supplierUUID },
    ];
    return this._postUserReplanFromDate({ planFromDate }, replacements);
  }

  fetchReplanDetails(
    webshopUUID: string,
    supplierUUID: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'supplierUUID', value: supplierUUID },
    ];

    return this._fetchReplanDetails(replacements).pipe(
      switchMap(data => of(new OrderMomentAdapter().transform(data)))
    );
  }
}
